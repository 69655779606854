/* Google font imports */
/* Open Sans 400 */
/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap'); */


/*MOBILE FIRST*/
/* style the component container */
.accordion_container {
  position: fixed;
  z-index: 998;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0); /* Making it overlay the entire screen */
  backdrop-filter: blur(0px);
  transition: all 0.01s; /* No border radius for full screen overlay */
  overflow: hidden;
}

.container_expanded {
  background-color: rgba(0, 0, 0, 0.95);
  backdrop-filter: blur(10px);
  height: 103vh;
  width: 101vw;
}

/* style the title button of the accordion menu */
.accordion_title {
  position: fixed;
  top: 5px;
  right: 5px;
  /* width: 100%;
  height: 30px;
  transition: height 0.5s ease-in; */
  border: none;
  outline: none;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  
  font-size: 25px;
  transform: scaleX(1.1);
  color: #333;
  background-color: transparent;
  text-shadow: 5 5 20px #000;
}


.accordion_title i {
  position: fixed;
  top: 5px;
  right: 5px;
}

.accordion_content {
  display: none; /* hidden by default, will be shown when active */
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content:space-evenly;
  align-items: center;
}


/*  
The .show_content class is added and removed from the accordion_content div in the JSX to achieve the show and hide effect for the content when the accordion is clicked.
*/
.show_content {
  display: flex;
}

/* Style the list item container divs */
.list_item_container {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vh;
}

/* Style the list items p text */
.list_item_container a {
  color: rgba(255, 255, 255, 0.9);
  font-size: 3rem;
  font-weight: 400;
  transform: scaleX(1.1);
  /* background-color: rgba(0,0,0,0.1); */
  border-radius: 100px;
  padding: 10px;
  text-transform: lowercase;
  transition: transform 0.2s ease-in-out;
  /* text-decoration: underline; */
  text-decoration-color: #222;
}

.list_item_container:hover {
  /* background-color: rgba(102, 102, 102, 0.05);
  transition: background-color 0.2s ease-in-out; */
}

/* Style list_item text when list_item_container is hovered */
.list_item_container a:hover {
  transform: scaleX(1.1);
}

.fas {
  transition: transform 0.3s ease;
}

.rotateNav {
  transform: rotate(45deg);
}

@media (max-width: 768px) {
    .accordion_container {
    
  }

  
}

/* desktop */
@media (min-width: 769px), (max-height: 430px) {
  .accordion_container {
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: row;
    z-index: 999;
    top: 0px;
    right: 0px;
    width: 40px;
    height: 30px;
    min-height: 30px;
    right: 0;
    min-width: 40px;
    background-color: transparent;
    backdrop-filter: blur(0px);
    border-radius: 10px;
    overflow: hidden;
    /* box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2); */
  }
  
  .container_expanded {
    height: auto;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(7px);
  }
  
  /* style the title button of the accordion menu */
  .accordion_title {
    position: fixed;
    width: 30px;
    height: 30px;
    top: 0px;
    right: 10px;

    border: none;
    outline: none;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    transform: scale(1.2);
    color: #333;
    background-color: transparent;

    /* -webkit-text-stroke: .3px #fff;
    text-stroke: .3px #fff; */
  }
  
  .accordion_content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.1s ease-in-out, opacity 0.3s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: auto;
  }
  
  .show_content {
    display: flex;
    height: auto;
    opacity: 1;
    max-height: 100px;
  }

  /* Style the list item container divs */
  .list_item_container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    padding: 0;
    /* border-top: 1px solid rgba(255, 255, 255, 0.3); */
  }
  
  /* Style the list items p text */
  .list_item_container a {
    border-radius: 10px;
    padding: 0 10px;
    color: #222;
    font-size: 1.5rem;
    text-transform: lowercase;
    text-decoration: none;
    font-weight: 700;
    transform: scaleX(1);
    background-color: none;
  }

  .list_item_container a:hover {
    transform: scaleX(0.95);
    background-color: none;
  }

  .aboutLink {
    transform: scaleX(1.3);
  }
  
  .creditsLink {
    transform: scaleX(1.2);
  }
  
  .appsLink {
    transform: scaleX(1.5);
  }

  .aboutMeLink {
    transform: scaleX(1.3);
  }
  
  .contactLink {
    transform: scaleX(1.2);
  }

  .homepage {
    background-color: transparent !important;
    backdrop-filter: none !important;
  }
}